import styled, {css} from 'styled-components'

const InfoParagraph = styled.p`
  font-size: ${({theme, fontSize}) => fontSize || theme.fontSize.s};
  font-weight:  ${({theme}) => theme.fontWeight.medium};
  color: ${({theme}) => theme.app_grey};
  margin-top: ${({margintop}) => margintop || '0'};
  display: flex;
  
  ${({moreImportant}) =>
    moreImportant &&
    css`
       color: ${({theme}) => theme.app_orange};
   `}
`;

export default InfoParagraph;
