import styled, {css} from 'styled-components';

const ModuleInput = styled.input`
  display: flex;
  padding: 10px 25px;
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: white;
  border: 1px solid #D5D5D5;
  width: ${({ width }) => width || '16vw'};
  min-width: 20rem;
  height: 3rem;
  ::placeholder {
    color: ${({ theme }) => theme.app_grey};
    font-size: 1.1rem;
  }
    &:focus{
      outline: none;
    } 
    
    ${({error}) =>
    error &&
    css`
        border: 1px solid ${({ theme }) => theme.app_orange};
   `}
`;
export default ModuleInput;
