import styled from "styled-components";
import React from "react";


const Wrapper = styled.ul`
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   padding: 0 0 3rem 0;
   margin: 0;
`;

const ContentWrapper = ({children}) => (
    <Wrapper>
        {children}
    </Wrapper>
);
export default ContentWrapper;