import React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import Button from "../../atoms/buttons/Button";
import ModalItem from "../../atoms/buttons/ModalItem";
import modal_icon from "../../../assets/icons/add-modules-icon.svg"
import {POPUP_MODULES} from "../../../utils/modules"
import PortalBackground from "../../atoms/wrappers/PortalBackground";
import ModalCloseButton from "../../atoms/buttons/ModalCloseButton";

const modalRoot = document.getElementById('modal-portal');

const PopUpContainer = styled.div`
   width: 40rem;
   height: auto;
   min-height: 60rem;
   max-height: 80vh;
   background-color: white;
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   justify-self: center;
   align-self: center;
   z-index: 999999;
   position: absolute; 
   padding: 3rem;
   align-items: center;
   padding-bottom: 8rem;
  
`;

const ModalHeaderWrapper = styled.div`
   display:flex;
   width: auto;
   height: auto;
   justify-content: center;
   margin-bottom: 2rem;
`;

const ModalHeader = styled.div`
   display: flex;
   margin: 0 auto;
   font-weight: ${({theme}) => theme.fontWeight.medium};
   font-size: ${({theme}) => theme.fontSize.xl};
   align-self: center;
   justify-content: center;
`;

const ModalIcon = styled.div`
   display: flex;
   width: 4rem;
   height:  4rem;
   background-image: url(${modal_icon});
   background-size: 3.5rem;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   margin-right: 1.5rem;
`;

const ButtonWrapper = styled.div`
   display: flex;
   width: 80%;
   height: auto;
   bottom: 2rem;
   position: absolute;
   align-self: center;
   justify-content: center;
`;

const ModulesWrapper = styled.div`
   display: flex;
   width: 85%;
   height: auto;
   overflow-y: auto;
   flex-direction: column;
   padding: 0 10px 13px 10px;
`;


class ModulesModal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.activeItem = 'none'
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    getValidModules = (NavItemType) => {
        switch (NavItemType) {
            case 'content':
            return POPUP_MODULES.filter(item => item.type !== 'ar').map(item =>
                <ModalItem onClick={() => this.setState({...this.state, activeItem: item.type})}
                           item={item.type}
                           key={item.type}
                           activeItem={this.state ? this.state.activeItem : null}>{item.ui_name}</ModalItem>);
            case 'map':
            return POPUP_MODULES.filter(item => item.type ==='poi' || item.type ==='path').map(item =>
                    <ModalItem onClick={() => this.setState({...this.state, activeItem: item.type})}
                               item={item.type}
                               key={item.type}
                               activeItem={this.state ? this.state.activeItem : null}>{item.ui_name}</ModalItem>);
            case 'ar':
                return POPUP_MODULES.filter(item => item.type ==='ar').map(item =>
                    <ModalItem onClick={() => this.setState({...this.state, activeItem: item.type})}
                               item={item.type}
                               key={item.type}
                               activeItem={this.state ? this.state.activeItem : null}>{item.ui_name}</ModalItem>);
            default:
                break;
        }
    };


    render() {
        const {toggleModal, message, showModal} = this.props;

        const checkIfModuleIsSelected = () => {
            if (this.state) {
                if (this.state.activeItem !== 'none') return false
            }
            else return true;

        };

        return ReactDOM.createPortal(
            <PortalBackground>
                <PopUpContainer>
                    <ModalCloseButton onClick={() => {
                        toggleModal({showModal: false, action: undefined})
                    }}/>
                    <ModalHeaderWrapper>
                        <ModalIcon/>
                        <ModalHeader>{message}</ModalHeader>
                    </ModalHeaderWrapper>
                    <ModulesWrapper>
                        {this.getValidModules(showModal.navItemType)}
                    </ModulesWrapper>
                    <ButtonWrapper>
                        <Button actionType={'add'} isDisabled={checkIfModuleIsSelected()} onClick={() => {
                            showModal.action(this.state.activeItem);
                            toggleModal({showModal: false, action: undefined});
                        }}>Dodaj moduł</Button>
                    </ButtonWrapper>
                </PopUpContainer>
            </PortalBackground>,
            this.el,
        );
    }
}

export default ModulesModal;