import styled, {css} from 'styled-components';

const TextArea = styled.textarea`
  display: flex;
  padding: 10px 25px;
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: white;
  border: 1px solid #D5D5D5;
  width: ${({ width }) => width || '100%'};
  height: 10rem;
  max-width: 100%;
  resize: none;
  ::placeholder {
    color: ${({ theme }) => theme.app_grey};
  }
    &:focus{
      outline: none;
    } 
    
  ${({insideTextModule}) =>
    insideTextModule && 
    css`
          height: 16rem;
    `}
  
  ${({error}) =>
    error &&
    css`
        border: 1px solid ${({ theme }) => theme.app_orange};
   `}
    
    
    
`;
export default TextArea;
