import React from "react";
import * as ReactDOM from "react-dom";
import styled, {css} from "styled-components";
import Button from "../../atoms/buttons/Button";
import modal_icon from "../../../assets/icons/media-icon.svg"
import trash_icon from "../../../assets/icons/trash-icon.svg"
import audiotrack_icon from "../../../assets/icons/audiotrack-icon.svg"
import video_icon from "../../../assets/icons/videocam-icon.svg"
import {API_URL, getFiles, uploadFile} from "../../../actions";
import {connect} from "react-redux";
import {FILE_TYPES} from "../../../utils/fileTypes";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import PortalBackground from "../../atoms/wrappers/PortalBackground";
import ModalCloseButton from "../../atoms/buttons/ModalCloseButton";

const modalRoot = document.getElementById('modal-portal');

const PopUpContainer = styled.div`
   width: 45vw;
   height: 75vh;
   min-height: 70rem;
   background-color: white;
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   justify-self: center;
   align-self: center;
   z-index: 999999;
   position: absolute; 
   padding: 3rem;
   align-items: center;
   padding-bottom: 8rem;
  
`;

const ModalHeaderWrapper = styled.div`
   display:flex;
   width: auto;
   height: auto;
   justify-content: center;
   margin-bottom: 2rem;
`;

const ModalHeader = styled.div`
   display: flex;
   margin: 0 auto;
   font-weight: ${({theme}) => theme.fontWeight.medium};
   font-size: ${({theme}) => theme.fontSize.xl};
   align-self: center;
   justify-content: center;
`;

const ModalIcon = styled.div`
   display: flex;
   width: 4rem;
   height:  4rem;
   background-image: url(${modal_icon});
   background-size: 3.5rem;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   margin-right: 1.5rem;
`;

const ButtonWrapper = styled.div`
   display: flex;
   width: 80%;
   height: auto;
   bottom: 2rem;
   position: absolute;
   align-self: center;
   justify-content: center;
`;

const TabsWrapper = styled.div`
   display: flex;
   width: 85%;
   height: 5rem;
   justify-content: space-between;
`;

const NoFileSelectedIcon = styled.div`
   display: flex;
   justify-self: center;
   align-self: center;
   width: 6rem;
   height: 6rem;
   background-image: url(${modal_icon});
   background-size: 6rem;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   opacity: .1;
`;

const AddWrapper = styled.div`
   display: flex;
   width: 85%;
   height: 75%;
   margin-top:4%;
   background-color: whitesmoke;
   background-size: cover;
   background-repeat: no-repeat;
   background-position-x: center;
   background-position-y: center;
   justify-content: center;
   align-content: center;
   position: relative;
`;

const SelectWrapper = styled.div`
   display: flex;
   width: 85%;
   height: 80%;
   margin-top: 4%;
   background-color: whitesmoke;
   justify-content: flex-start;
   align-items: flex-start;
   flex-direction: row;
   flex-wrap: wrap;
   overflow: scroll;
`;


const FileWrapper = styled.div`
   display: flex;
   width: 11vw;
   height: 15vw;
   background-color: white;
   margin-right: 5px;
   margin-bottom: 5px;
   color: ${({theme}) => theme.app_grey};
   font-size: ${({theme}) => theme.fontSize.s};
   flex-direction: column;
   word-wrap: break-word;
   padding: 6px;
   border-radius: 0.5rem;
   opacity: 0.2;
   
   span{
      color: #4d4d4d;
      font-weight: ${({theme}) => theme.fontWeight.medium};
      margin-top: 3px;
   }
   
    ${({fileURL, selectedURL}) =>
    (fileURL !== undefined && fileURL === selectedURL) &&
    css`
        background-color:  ${({theme}) => theme.app_orange};
        color: #fafafa;
    `}
    

    ${({isFileValid}) =>
    isFileValid &&
    css`
        opacity: 1;
        cursor: pointer;
    `}
   
`;

const FileImage = styled.div`
   display: flex;
   width: 100%;
   height: 11vw;
   max-height: 11vw;
   background-color: gray;
   margin-bottom: 2px;
   background-size: cover;
   background-repeat: no-repeat;
   background-position-x: center;
   background-position-y: center;
   
    ${({imageUrl}) =>
    imageUrl &&
    css`
        background-image: url('${imageUrl}');
    `}
    
    ${({audio}) =>
    audio &&
    css`
        background-image: url(${audiotrack_icon});
        background-size: 4rem;
        opacity: .2;
    `}
    
    ${({video}) =>
    video &&
    css`
        background-image: url(${video_icon});
        background-size: 4rem;
        opacity: .2;
    `}
    
    
`;

const Tab = styled.button`
  display: flex;
  width: 48%;
  height: 100%;
  background-color: white;
  border: none;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.8rem;
  font-size: ${({theme}) => theme.fontSize.l};
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.1s ease-in-out;
  -webkit-transition: background-color 0.1s ease-in-out;
  padding-left: 2rem;
  
  ${({tab, activeTab}) =>
    tab === activeTab &&
    css`
        background-color: ${({theme}) => theme.app_orange};
    `}
  &:focus{
     outline: none;
  }
  
  &:hover{
     background-color: ${({theme}) => theme.app_orange};
  }
`;

const StyledInput = styled.input`
   display: none;
`;

const StyledLabel = styled.label`
  padding: 0;
  background-color: black;
  border: none;
  border-radius: 0.5rem;
  font-weight: ${({theme}) => theme.fontWeight.medium};
  font-size: ${({theme}) => theme.fontSize.s};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width:6rem;
  height: 100%;
  width: 10rem;
  color: white;
  margin-left: 1rem;
  &:focus{
      outline: none;
  }
`;

const FileChoseWrapper = styled.div`
  width: 85%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
  margin-top: 2%;
  
  p{
    font-weight: ${({theme}) => theme.fontWeight.regular};
    font-size: ${({theme}) => theme.fontSize.s};
    color: ${({theme}) => theme.app_grey};
  }
`;

const InfoMessageWrapper = styled.div`
  width: 85%;
  height: 3.5rem;
  margin-top: 1.5rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  color: ${({theme}) => theme.app_orange};
  font-size: 1rem;
  
  p{
      font-weight: ${({theme}) => theme.fontWeight.medium};
      font-size: ${({theme}) => theme.fontSize.s};
      margin-top: 0.5rem;
  }
  
  span{
    color: black;
  }

`;

const RemoveButton = styled.div`
  position: absolute;
  right: -2rem;
  top: calc(50% - 4rem);
  display: flex;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: whitesmoke;
  background-image: url(${trash_icon});
   background-size:2rem;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   cursor: pointer;
   
   &:hover{
   background-color:  ${({theme}) => theme.app_orange};;
   }
   
`;


class FilesModal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.state = {
            activeTab: 'add',
            showUploadSuccessMessage: false,
            backgroundUrl: null,
        }
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
        // root.style.filter = 'blur(10px)';
        // this.props.getFilesAction(FILE_TYPES.IMAGE);
        this.props.getFilesAction();
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
        // root.style.filter = 'blur(0px)';
        this.props.clearImportedFiles();
        this.props.toggleModal({showModal: false, action: undefined});
    }

    handleUploadFile = (e) => {
        if (e.target.files.length) {
            let imageUrl = URL.createObjectURL(e.target.files[0]);
            this.setState({
                file: e.target.files[0],
                backgroundUrl: {backgroundImage: "url(" + imageUrl + ")"},
                showUploadSuccessMessage: false,
            });
        }
    };

    checkIfFileIsUploaded = () => {
        return !this.state.file;
    };

    checkIfFileIsSelected = () => {
        return !this.state.selectedFile;
    };


    removeFile = () => {
        this.setState({...this.state, file: null, backgroundUrl: null})
    };


    refreshUploadedFiles = async () => await this.props.getFilesAction();

    saveImage = async () => {
        let imageFile = new FormData();
        imageFile.append("imageName", "multer-image-" + Date.now());
        imageFile.append("file", this.state.file);
        let newUrl = await this.props.uploadFileAction(imageFile);
        // this.props.toggleModal({showModal: false, action: undefined});
        if (newUrl) this.setState(prevState => ({
            file: null,
            backgroundUrl: null,
            showUploadSuccessMessage: prevState.file.name,
        }));

        await this.refreshUploadedFiles();
    };


    getCoverImageByFileType = (file) => {
        switch (file.type.split('/').shift().toUpperCase()) {
            case FILE_TYPES.AUDIO:
                return <FileImage audio/>;

            case FILE_TYPES.IMAGE:
                return <FileImage imageUrl={`${API_URL}${file.url}`}/>;

            case FILE_TYPES.VIDEO:
                return <FileImage video/>;

            default:
                return <FileImage/>
        }
    };

    getSelectedURL = () => {

        let selectedUrlField = this.getField(
            this.props.showModal.target.field.name,
            this.props.moduleEditableData);

        if (this.state.selectedFile && this.state.selectedFile.url) {
            return this.state.selectedFile.url;
        }
        else if (selectedUrlField.fieldValues.length) {
            return selectedUrlField.fieldValues[this.props.showModal.target.index].value
        }
        else return null;
    };

    generateFilesGrid = (data) => {
        return data.map(file =>
            <FileWrapper
                isFileValid={this.isFileValid(file)}
                key={file._id}
                onClick={() => {
                    if (this.isFileValid(file)) this.setState({...this.state, selectedFile: file});
                }}
                fileURL={file.url}
                selectedURL={this.getSelectedURL()}>
                {this.getCoverImageByFileType(file)}
                {new Date(file.createdAt).toUTCString()}
                <span>{file.name}</span>
            </FileWrapper>
        );
    };

    // --- GET FIELD FROM MODULE ---
    getField = (type, module) => ({
        ...module.fields.find(field => field.name === type),
    });


    handleFieldChange = (responseValue) => {

        const {showModal, moduleEditableData, addFileToModule} = this.props;

        if (moduleEditableData && moduleEditableData.type === 'gallery') {
            let newModule = moduleEditableData;
            newModule.fields[0].fieldValues.push({value: responseValue || this.state.selectedFile.url});
            addFileToModule(newModule);
        }

        if (moduleEditableData && showModal.target && moduleEditableData.type !== 'gallery') {
            let newModule = moduleEditableData;
            this.getField(showModal.target.field.name, newModule).fieldValues[showModal.target.index].value = responseValue || this.state.selectedFile.url;
            addFileToModule(newModule);
        }
    };


    isFileValid = (file) => {
        switch (this.props.showModal.target.field.name) {
            case 'photo' :
            case 'photos' :
            case '3d_model_notification_image' :
                return file.type.split('/')[0] === 'image';
            case 'link' :
                return file.type.split('/')[0] === this.props.moduleEditableData.type;
            case '3d_model' : {
                switch (this.props.showModal.target.index) {
                    case 0 :
                        return file.name.split('.')[1] === 'gltf';
                    case 1 :
                        return file.name.split('.')[1] === 'bin';
                    case 2 :
                        return file.name.split('.')[1] === 'png';
                    default:
                        return false
                }
            }
            default:
                return false
        }
    };

    render() {
        const {toggleModal, message, showModal, fetchedFiles} = this.props;

        const {activeTab, file} = this.state;

        return ReactDOM.createPortal(
            <PortalBackground>
                <PopUpContainer>
                    <ModalCloseButton onClick={() => {
                        toggleModal({showModal: false, action: undefined})
                    }}/>
                    <ModalHeaderWrapper>
                        <ModalIcon/>
                        <ModalHeader>{message}</ModalHeader>
                    </ModalHeaderWrapper>
                    <TabsWrapper>
                        <Tab onClick={() => this.setState({...this.state, activeTab: 'add'})}
                             tab={'add'}
                             activeTab={this.state ? activeTab : null}>Wgraj</Tab>
                        <Tab onClick={() => this.setState({
                            ...this.state,
                            activeTab: 'select',
                            file: null,
                            backgroundUrl: null,
                            showUploadSuccessMessage: false
                        })}
                             tab={'select'}
                             activeTab={this.state ? activeTab : null}>wybierz</Tab>
                    </TabsWrapper>
                    <InfoMessageWrapper>
                        {this.state.showUploadSuccessMessage &&
                        <>
                            <span>{this.state.showUploadSuccessMessage}</span>
                            <p> Plik zostal dodany do biblioteki. Przejdz do zakładki 'Wybierz' aby go wybrać.</p>
                        </>
                        }
                    </InfoMessageWrapper>
                    {activeTab === 'add' ?
                        <>
                            <AddWrapper style={this.state.backgroundUrl}>
                                {!file && <NoFileSelectedIcon/>}
                                {file && <RemoveButton onClick={() => this.removeFile()}/>}
                            </AddWrapper>
                            <FileChoseWrapper>
                                <p>{(file) ? file.name : "Nie wybrano pliku..."}</p>
                                <StyledLabel htmlFor="file-upload">
                                    Przeglądaj
                                    <StyledInput
                                        type="file"
                                        name="file"
                                        accept="image/*, audio/*, video/*, .bin, .gltf"
                                        id="file-upload"
                                        onChange={this.handleUploadFile}
                                    />
                                </StyledLabel>
                            </FileChoseWrapper>
                        </>
                        :
                        <SelectWrapper>
                            {fetchedFiles && this.generateFilesGrid(fetchedFiles.data)}
                        </SelectWrapper>
                    }
                    <ButtonWrapper>

                        {this.state.activeTab === 'add' ?
                            <Button
                                actionType={'add'}
                                isDisabled={this.checkIfFileIsUploaded()}
                                onClick={() => {
                                    this.saveImage();
                                }
                                }>Wgraj plik</Button>
                            :
                            <Button actionType={'add'} isDisabled={this.checkIfFileIsSelected()} onClick={() => {

                                // --- PUSH NEW VALUE TO REDUX MODULE ---
                                this.handleFieldChange();

                                toggleModal({showModal: false, action: undefined});
                            }}>Wybierz plik</Button>
                        }
                    </ButtonWrapper>
                </PopUpContainer>
            </PortalBackground>,
            this.el,
        );
    }
}


const mapStateToProps = state => ({
    fetchedFiles: state.fetchedFiles,
    moduleEditableData: state.moduleEditableData || null,
});

const mapDispatchToProps = dispatch => ({
    getFilesAction: () => dispatch(getFiles()),
    clearImportedFiles: () => dispatch({type: ACTION_TYPES.CLEAR_IMPORTED_FILES}),
    addFileToModule: (module) => dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: module}),
    uploadFileAction: (file) => dispatch(uploadFile(file)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FilesModal);