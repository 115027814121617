import React from "react";
import styled from "styled-components";
import {useDispatch, useSelector} from 'react-redux';

import {SEND_REPORT} from "../../actions/requestTypes";
import {requestSendReport} from '../../actions/index';

// Components
import {Col} from 'react-bootstrap';
import Row from "../atoms/Row";
import Button from "../atoms/buttons/Button"

const DownloadColumn = styled(Col)`
    text-align: right;
`;

const DownloadButton = styled(Button)`
    float: right;
`;

const User = ({user, onChange}) => {
    const dispatch = useDispatch();
    const [users, setUsers] = useSelector(state => (state.users && state.users.results) ? state.users.results : []);

    const sendReport = () => {
        //param: userId
        dispatch(requestSendReport({
            ...SEND_REPORT,
            },
            user._id,
            onChange
        ));
    }

    return (
        <>
            <Row>
                <Col sm={4}> {user.firstName} </Col>
                <Col sm={4}> {user.login} </Col>
                <DownloadColumn sm={4}>  
                    <DownloadButton onClick={sendReport}> WYSLIJ </DownloadButton>
                </DownloadColumn>
            </Row>
        </>
    )
};

export default User;