import {createGlobalStyle} from 'styled-components'


const GlobalStyle = createGlobalStyle`

    @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Anton&display=swap');
  
    *, *::before, *::after{
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }    
    
    html{
    font-size: 62.5%;
    height: 100%;
    overflow-y: scroll;
    }
    
    body{
    font-size: 1.6rem;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #F4F7F9;
    }
`;





export default GlobalStyle;
