import styled from "styled-components";
import React from "react";
import modules_icon from "../../assets/icons/modules-icon.svg"


const SeparatorWrapper = styled.div`
   width: 100%;
   display: flex;
   align-items: center;
   text-transform: uppercase;
`;

const HorizontalSeparator = styled.div`
   width: 100%;
   height: 1px;
   opacity: .3;
   display: flex;
   background-color: ${({theme}) => theme.app_grey};
   align-self: flex-end;
   position: absolute;
   margin-top: 1rem;
`;

const ModulesIcon = styled.div`
   height: 1.2rem;
   width: 2.1rem;
   display: flex;
   background-image: url(${modules_icon});
   background-size: cover;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   margin-right: 1rem;
`;


const Separator = ({children}) => (
    <SeparatorWrapper>
        <ModulesIcon/>
        {children}
        <HorizontalSeparator/>
    </SeparatorWrapper>
);

export default Separator;
