import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";

const ListHeaderContainer = styled(Container)`
    width: 100%;
`;

const ListHeaderRow = styled(Row)`

    
    width: 100%;
    align-items: center;
    margin: 0;
    
    background-color: transparent;
    
    font-size: 12px
`;

const ListHeaderCol = styled(Col)`
    font-weight: bold;
`;

const ListHeader = ({children}) => {

    return (
        <ListHeaderContainer fluid>
            <ListHeaderRow>
                {children}
            </ListHeaderRow>
        </ListHeaderContainer>
    )
};

export {ListHeader, ListHeaderCol};