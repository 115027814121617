import React, {useEffect, useState, useRef} from "react";
import ModuleInputWrapper from "../../molecules/ModuleInputWrapper";
import {INPUT_TYPES, MODULES} from "../../../utils/modules";
import ActionContainer, {ActionButton} from "../../molecules/ActionContainer";
import {ModuleWrapper} from "../../atoms/wrappers/ModuleWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {ModuleHeading} from "../../atoms/headings/ModuleHeading";
import {useDispatch, useSelector} from "react-redux";
import {addItem, editItem} from "../../../actions";
import {EDIT_MODULE} from "../../../actions/requestTypes";
import {useLocation} from "react-router-dom";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {
    checkIsModuleLoading,
    fieldEmptyValuesValidation,
    getLastElement,
    getModuleId
} from "../../../utils/moduleFunctions";
import _ from "lodash";
import ModuleSpinner from "../../molecules/ModuleSpinner";
import {useDrag, useDrop} from "react-dnd";
import {DnDWrapper} from "../../atoms/wrappers/DnDWrapper";


const ListModule = ({module, index, id, removeModuleTrigger, disabled = true, moveCard,canModuleDrag,saveNavigationItemOrder}) => {

    //  ############### DnD ###############
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'MODULE',
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex
        },
    });
    const [{isDragging}, drag] = useDrag({
        item: {type: 'MODULE', id, index},
        canDrag: canModuleDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => saveNavigationItemOrder(),
    });
    drag(drop(ref));

//  ############### GENERAL MODULE HANDLER ###############

    // --- INITIALIZATION ---
    let location = useLocation();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [emptyFieldValues, setEmptyFieldValues] = useState([]);

    // --- GET MODULE VALUES FROM REDUX ---
    const moduleEditableData = useSelector(state => state.moduleEditableData);
    const currentNavItem = useSelector(state => {
            if (state.navigationItemController && state.navigationItemController.length)
                return getLastElement(state.navigationItemController);
        }
    );


    (() => {
        if (!isDisabled && moduleEditableData) module = moduleEditableData;
    })();

    // --- GET TYPE FROM ENUM ---
    const getModuleType = () => MODULES[module.type.toUpperCase()];

    //--- CHECK IF MODULE IS CREATED BY USER ---
    const moduleToAdd = useSelector(state => state.moduleToAdd);
    const isCreatedByUser = (moduleToAdd === getModuleType().API_NAME);

    // --- GET FIELD FROM MODULE ---
    const getField = (type, moduleToSearch) => ({
        ...moduleToSearch.fields.find(field => field.name === type),
        module_index: index,
    });

    //--- ADD MODULE TO REDUX IF IS CREATED BY USER ---
    useEffect(() => {
        if (isCreatedByUser) {
            const moduleToEdit = _.cloneDeep(module);
            dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: moduleToEdit});
            setIsDisabled(false);
            setEmptyFieldValues([]);
        }
    }, []);

    // --- EDIT REJECTED ---
    const resetValuesToDefault = () => {
        if (isCreatedByUser) {
            dispatch({type: ACTION_TYPES.MODULE_CANCEL_ADDING});
        }
        else {
            if (currentNavItem.modules && currentNavItem.modules.length) {
                module = currentNavItem.modules[index];
            }
            dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
        }
        setEmptyFieldValues([]);
        setIsDisabled(true)
    };

    // --- SAVE MODULE ---
    const saveModuleTrigger = () => {
        let emptyFields = fieldEmptyValuesValidation(itemsField);

        if (!emptyFields.length) {
            if (isCreatedByUser) {
                dispatch(addItem(getModuleId(location), moduleEditableData, currentNavItem.modules));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.SAVE_CREATED_MODULE});
            } else {
                dispatch(editItem(getModuleId(location), EDIT_MODULE, moduleEditableData));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
                setIsDisabled(true)
            }
        } else setEmptyFieldValues(emptyFields)

    };

    // --- HANDLE LOADING SPINNER ---
    const isModuleLoading = useSelector(state => state.isModuleLoading);

//  ############################################################

    // --- REDUX FIELDS ---
    const itemsField = getField(MODULES.ITEMS.API_NAME, module);

    // --- REMOVE PHOTO ---
    const removeFieldTrigger = (index) => {
        const newModule = {...moduleEditableData};
        getField(MODULES.ITEMS.API_NAME, newModule).fieldValues.splice(index, 1);
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
        setEmptyFieldValues([]);
    };

    // --- FIELD VALUE CHANGE ---
    const handleValueChange = (value, index) => {
        let newModule = {...moduleEditableData};
        getField(MODULES.ITEMS.API_NAME, newModule).fieldValues[index].value = value;
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
    };

    // --- ADD NEW FIELD ---
    const addNewFieldValue = () => {
        let newModule = {...moduleEditableData};
        getField(MODULES.ITEMS.API_NAME, newModule).fieldValues.push({value: ""});
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
    };

    // --- DATA VALIDATION ---
    const validateInput = (value, fieldToValidate, index) => {
        if (value.trim() === "") setEmptyFieldValues([...emptyFieldValues, index]);
        else if (emptyFieldValues.length) setEmptyFieldValues(emptyFieldValues.filter(valueIndex => valueIndex !== index));
    };

    // --- FIELD VALUE VALIDATION ---
    const isValueEmptyError = (index, emptyValues) => {
        if (emptyValues.length)
            return emptyValues.some(value => value === index);
        else return undefined;
    };

    const opacity = isDragging ? 0 : 1;
    const getRef = () => isCreatedByUser? null : ref;

    return (
        <DnDWrapper ref={getRef()} style={{opacity}}>
            <ModuleHeading canModuleDrag={canModuleDrag} isDisabled={isDisabled} isLoading={checkIsModuleLoading(module._id, isModuleLoading)}>
                {getModuleType().UI_NAME}
            </ModuleHeading>
            <ModuleWrapper>
                {checkIsModuleLoading(module._id, isModuleLoading) && <ModuleSpinner/>}
                <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                    {itemsField.fieldValues.map((listItem, index) =>
                        <HorizontalModuleWrapper key={index}>
                            <ModuleInputWrapper isDisabled={isDisabled}
                                                fieldValue={listItem.value}
                                                handleChange={(newValue) => {
                                                    handleValueChange(newValue, index);
                                                    validateInput(newValue, itemsField, index)
                                                }}
                                                width={'100%'}
                                                inputType={INPUT_TYPES.TEXT}
                                                saveModuleTrigger={saveModuleTrigger}
                                                error={isValueEmptyError(index, emptyFieldValues)}
                            />
                            {itemsField.fieldValues.length > 1 ?
                                <ActionButton type={'remove'} onClick={() => removeFieldTrigger(index)}/> : null}
                        </HorizontalModuleWrapper>
                    )}
                    <HorizontalModuleWrapper>
                        <ActionButton type={'add'} onClick={addNewFieldValue}/>
                    </HorizontalModuleWrapper>
                </ModuleContentWrapper>
                <ActionContainer isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                                 removeModuleTrigger={removeModuleTrigger} idToRemove={module._id}
                                 resetValuesToDefault={() => resetValuesToDefault()}
                                 saveModuleTrigger={saveModuleTrigger}
                                 module={module}/>
            </ModuleWrapper>
        </DnDWrapper>
    )
};
export default ListModule;