import styled from "styled-components";
import app_icon from "../../../assets/icons/app-icon.png"


export const Icon = styled.div`
   width: 8rem;
   height: 8rem;
   background-image: url(${app_icon});
   background-size: cover;
   background-position: 50% 50%;
   background-repeat: no-repeat;
`;