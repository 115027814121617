import React from "react";
import styled from "styled-components";
import AuthForm from "../../organisms/AuthForm";

const AuthWrapper = styled.div`
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
`;

const AuthBackground = styled.img`
   width: 100%;
    height: 100%;
   position:absolute;
   opacity: 0;
   transition: opacity 1s ease-in-out;
   -webkit-transition: opacity 1s ease-in-out;
`;

const Wrapper = styled.div`
  display:flex;
  overflow: hidden;
`;

const AuthView = () => {
    return (
        <Wrapper>
            <AuthBackground onLoad={(e) => e.target.style.opacity='1'}/>
            <AuthWrapper>
                <AuthForm/>
            </AuthWrapper>
        </Wrapper>
    )
};

export default AuthView;
