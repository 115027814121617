import React from 'react';
import styled from "styled-components";
import Sidebar from "../components/organisms/Sidebar";
import UserInfo from "../components/organisms/UserInfo";


const ContentWrapper = styled.div`
   display: flex;
   min-height: 100vh;
   width: 100%;
   justify-content: center;
   margin-left: 14%;
`;
const ContentContainer = styled.div`
   display: flex;
   height: 100%;
   width: 90%;
   position: relative;
   flex-direction: column;
   padding-top: 9rem;
`;

const SidebarTemplate = ({children}) => {
    return (
        <>
            <Sidebar/>
            <ContentWrapper>
                <ContentContainer>
                    <UserInfo/>
                    {children}
                </ContentContainer>
            </ContentWrapper>
        </>
    )
};
export default SidebarTemplate;
