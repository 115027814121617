export const changeModuleFieldValue = (module, id, value) => {

    const moduleToReturn = {...module};

    for (let field of moduleToReturn[0].fields) {
        if (field.fieldValues[0]._id === id) {
            field.fieldValues[0].value = value;
            break;
        }
    }

    return moduleToReturn;
};


export const getModulesAfterRemove = (modules, id) => {
    const modulesToReturn = [...modules];
    modulesToReturn[id].isRemoved = true;

    return modulesToReturn;
};


//--- HANDLE VALUES SPLIT / FLAT ---
export const splitValues = (value) => {

    if (value) {
        let latitude = value.split(',').shift().trim();
        let longitude = value.split(',').pop().trim();
        return {latitude, longitude}
    }
    else return {latitude: '', longitude: ''}


};
export const getFlatValue = (values) => values.latitude + ',' + values.longitude;


export const getModuleId = (location) => location.pathname.split('/')[2];


export const moduleEmptyFieldsValidation = (module) => {
    let emptyFields = [];
    for (let field of module.fields) {
        for (let fieldValue of field.fieldValues) {
            if (fieldValue.value.trim() === "") {
                emptyFields.push(field);
                break;
            }
        }
    }
    return emptyFields
};


export const fieldEmptyValuesValidation = (field) => {
    let emptyValues = [];

    field.fieldValues.length && field.fieldValues.map((fieldValue, index) => {
            if (fieldValue.value.trim() === "") emptyValues.push(index);
        }
    );
    return emptyValues
};

export const mapIdToModuleName = (id, navigationItems) => {
    if (navigationItems) {
        for (let item of navigationItems) {
            if (item._id === id) return item.name
        }
    }
    return id;
};

export const getNavItem = (id, navigationItems) => {
    if (navigationItems) {
        for (let item of navigationItems) {
            if (item._id === id) return item
        }
    }
    return id;
};

export const getFieldValue = (field) => {
    if (field && field.fieldValues) return field.fieldValues[0].value;
    else return '';
};

export const getNavItemTypeById = (id, navigationItems) => navigationItems.find(item => item._id === id).type;

export const getLastElement = array => array? array[array.length - 1] : {};

// --- HANDLE LOADING SPINNER ---
export const checkIsModuleLoading = (moduleId, loadingId) => {
    if (loadingId) return moduleId === loadingId;
    else return false
};


// --- DATA VALIDATION ---
export const isError = (fieldToValidate, moduleEmptyFields) => {
    if (moduleEmptyFields.length)
        return moduleEmptyFields.some(field => field.name === fieldToValidate.name);
    else return undefined;
};


