import styled from "styled-components";

export const HorizontalModuleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${({ width }) => width || '100%'};
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 1.5rem;
  
  p{
    font-size: ${({theme}) => theme.fontSize.s};
  }
`;