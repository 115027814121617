import React from "react";
import _ from "lodash"
import styled, {css} from "styled-components";
import edit_icon from '../../assets/icons/edit-icon.svg'
import remove_icon from '../../assets/icons/remove-icon.svg';
import edit_icon_dark from '../../assets/icons/edit-icon-dark.svg';
import check_icon from '../../assets/icons/check-icon.svg';
import check_icon_dark from '../../assets/icons/check-icon-dark.svg';
import close_icon from '../../assets/icons/close.svg';
import add_icon from '../../assets/icons/add-modules-icon.svg';
import {useDispatch, useSelector} from "react-redux";
import {ACTION_TYPES} from "../../actions/actionTypes";

const ActionWrapper = styled.div`
  display: flex;
  width: 15%;
  align-items: center;
  justify-content: center;
`;

const VerticalSeparator = styled.div`
  display: flex;
  width: 1px;
  background-color: #E0E3E5;
`;

export const ActionButton = styled.div`
  display: flex;
  width: 3rem;
  min-width: ${({ minWidth }) => minWidth || '3rem'};
  height: 3rem;
  margin-left: 1rem;
  background-image: url(${remove_icon});
  border-radius: 0.5rem;
  background-size: 1.8rem;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: unset;
  transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0s ease-in-out; // disable animation
   
   &:hover{
     background-color: ${({theme}) => theme.app_grey};
   }
  
  ${({type}) =>
    type === 'edit' &&
    css`
      background-image: url(${edit_icon});
      
            &:hover{
               background-color: ${({theme}) => theme.app_orange};
               background-image: url(${edit_icon_dark});
            }
   `}
   
   ${({type}) =>
    type === 'save' &&
    css`
      background-image: url(${check_icon});
      
            &:hover{
               background-color: ${({theme}) => theme.app_orange};
               background-image: url(${check_icon_dark});
            }
   `}
   
    ${({type}) =>
    type === 'close' &&
    css`
      background-image: url(${close_icon});
      
            &:hover{
              background-color: ${({theme}) => theme.app_grey};
            }
   `}
   
    ${({type}) =>
    type === 'close' &&
    css`
      background-image: url(${close_icon});
      
            &:hover{
              background-color: ${({theme}) => theme.app_grey};
            }
   `}
   
    ${({type}) =>
    type === 'add' &&
    css`
      background-image: url(${add_icon});
      margin-left: 0;

            &:hover{
              background-color: ${({theme}) => theme.app_orange};
            }
   `}
   
    ${({removeGalleryItem}) =>
    removeGalleryItem  &&
    css`
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      background-color: white;
      
      &:hover{
              background-color: ${({theme}) => theme.app_grey};
            }
      
   `}
`;

const ActionContainer = ({isDisabled, setIsDisabled, removeModuleTrigger, idToRemove, resetValuesToDefault, saveModuleTrigger, fileUrl, noAction,module}) => {

    const dispatch = useDispatch();
    const isModuleEditing = useSelector(state => state.isModuleEditing);

    const moduleToEdit = _.cloneDeep(module);

    const getButtonsForEditAndRemove = () => {
        return (isModuleEditing ? null :
            <>
                {!noAction && <ActionButton type={'edit'} onClick={() => {
                    dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: true});
                    dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: moduleToEdit});
                    setIsDisabled(false)
                }}/>}
                <ActionButton type={'remove'} onClick={() =>
                    removeModuleTrigger(idToRemove)}/>
            </>)
    };

    const getButtonsForSaveAndClose = () => (
        <>
            <ActionButton type={'save'} onClick={() => saveModuleTrigger()}/>
            <ActionButton type={'close'} onClick={() => resetValuesToDefault()}/>
        </>
    );

    return (
        <>
            <VerticalSeparator/>
            <ActionWrapper>
                {!isDisabled ? getButtonsForSaveAndClose() : getButtonsForEditAndRemove()}
            </ActionWrapper>
        </>
    )
};
export default ActionContainer;