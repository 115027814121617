import React, {useCallback, useEffect, useState} from "react";
import Separator from "../../molecules/Separator";
import {Prompt, useLocation, useParams} from "react-router-dom";
import ModulesModal from "../../organisms/modals/ModulesModal";
import ActionBar from "../../molecules/ActionBar";
import ContentWrapper from "../../organisms/ContentWrapper";
import WithSpinner from "../../HOC/WithSpinner";
import {useDispatch, useSelector} from "react-redux";
import HeadingModule from "../../organisms/modules/HeadingModule";
import {fetchModules, getAllNavigationItemList, removeItem, updateNavigationItemModulesOrder} from "../../../actions";
import {MOCK_MODULES, MODULES} from "../../../utils/modules";
import {MODAL_TYPES} from "../../../utils/modalTypes";
import ModalAction from "../../organisms/modals/ModalAction";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import FilesModal from "../../organisms/modals/FilesModal";
import TextModule from "../../organisms/modules/TextModule";
import HeaderTextModule from "../../organisms/modules/HeaderTextModule";
import ShortMenuModule from "../../organisms/modules/ShortMenuModule";
import FilterModule from "../../organisms/modules/FilterModule";
import AudioModule from "../../organisms/modules/AudioModule";
import VideoModule from "../../organisms/modules/VideoModule";
import PhotoModule from "../../organisms/modules/PhotoModule";
import ListModule from "../../organisms/modules/ListModule";
import GalleryModule from "../../organisms/modules/GalleryModule";
import PathModule from "../../organisms/modules/PathModule";
import PoiModule from "../../organisms/modules/PoiModule";
import ShortLinkModule from "../../organisms/modules/ShortLinkModule";
import LinkModule from "../../organisms/modules/LinkModule";
import ModalError from "../../organisms/modals/ModalError";
import {getLastElement, getModuleId} from "../../../utils/moduleFunctions";
import LinkModal from "../../organisms/modals/LinkModal";
import NavigationItemData from "../../organisms/modules/NavigationItemData";
import update from 'immutability-helper'
import {DndProvider} from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import styled from "styled-components";
import _ from 'lodash'
import ArModule from "../../organisms/modules/ArModule";
import YesNoModule from "../../organisms/modules/YesNoModule";
import InputTextModule from "../../organisms/modules/InputTextModule";
import InputNumberModule from "../../organisms/modules/InputNumberModule";
import SelectOneModule from "../../organisms/modules/SelectOneModule";
import SaveModule from "../../organisms/modules/SaveModule";
import SelectOneMultipleModule from "../../organisms/modules/SelectOneMultipleModule";
import SelectMultipleModule from "../../organisms/modules/SelectMultipleModule";

const ContentWrapperWithSpinner = WithSpinner(ContentWrapper);


const Wrapper = styled.ul`
   width: 100%;
   height: auto;
   display: flex;
   flex-direction: column;
   padding: 0 0 3rem 0;
   margin: 0;
`;

const NavigationItem = () => {

    let location = useLocation();
    const dispatch = useDispatch();
    const navigationItemController = useSelector(state => state.navigationItemController);
    const moduleEditableData = useSelector(state => state.moduleEditableData);
    const [canModuleDrag, setCanModuleDrag] = useState(true);

    let {navItemId} = useParams();
    const validateNavItemId = () => navItemId.match(/^[0-9a-fA-F]{24}$/);

    // --- HANDLE LOCATION CHANGE ---
    useEffect(() => {
        console.log(location.pathname + " change!");
        if (validateNavItemId()) {
            dispatch(getAllNavigationItemList());
            dispatch(fetchModules(getModuleId(location)));
        }
    }, [location]);


    useEffect(() => {
        if (moduleEditableData) {
            if(canModuleDrag){
                setCanModuleDrag(false);
            }
        }
        else if(!canModuleDrag){
            setCanModuleDrag(true);
        }
    }, [moduleEditableData]);

    const isLoading = useSelector(state => state.isLoading);
    const modules = useSelector(state => state.navigationItemController.length ? getLastElement(state.navigationItemController).modules : []);

    useEffect(() => {
        setDraggableModules(_.cloneDeep(modules));
    }, [modules]);


    const [draggableModules, setDraggableModules] = useState(modules);

    const moduleToAddType = useSelector(state => state.moduleToAdd);
    const currentNavigationItem = useSelector(state => getLastElement(state.navigationItemController));

    const isError = useSelector(state => state.isError);

    const [Modal, toggleModal] = useState({showModal: false, action: undefined, message: "", type: undefined});


    const addModuleTrigger = () => {
        toggleModal({
            showModal: true,
            action: (type) => {
                dispatch({type: ACTION_TYPES.ADD_MODULE, payload: type})
            },
            message: MODAL_TYPES.ADD_MODULE.MESSAGE,
            type: MODAL_TYPES.ADD_MODULE.TYPE,
            navItemType: currentNavigationItem.type,
        });
    };

    const setLinkTrigger = (field, isModuleSaved, index = 0, linkType = null) => {
        toggleModal({
            showModal: true,
            action: (type) => {
                console.log("link saved! " + type);
            },
            message: MODAL_TYPES.SET_LINK.MESSAGE,
            type: MODAL_TYPES.SET_LINK.TYPE,
            target: {field, index},
            linkType,
            isModuleSaved
        });
    };


    const manageFileTrigger = (field, index = 0) => {
        toggleModal({
            showModal: true,
            action: undefined,
            message: MODAL_TYPES.MANAGE_IMAGES.MESSAGE,
            type: MODAL_TYPES.MANAGE_IMAGES.TYPE,
            target: {field, index}
        });
    };

    //TODO: refactor as... container? contextAPI ? not smart to push it down to all modules.
    const removeModuleTrigger = (id) => {
        toggleModal({
            showModal: true,
            action: () => {
                dispatch(removeItem(getModuleId(location), id));
            },
            message: MODAL_TYPES.REMOVE_MODULE.MESSAGE,
            type: MODAL_TYPES.REMOVE_MODULE.TYPE,
        });
    };

    const saveNavigationItemOrder = () => {
        let modulesOrdered = draggableModules.map((module, index) => ({...module, order: index}));
        dispatch(updateNavigationItemModulesOrder(getLastElement(navigationItemController), modulesOrdered));
        console.log('---- ORDER SAVED ---', modulesOrdered);
    };

    const moveCard = useCallback(
        (dragIndex, hoverIndex) => {
            const dragCard = draggableModules[dragIndex];
            setDraggableModules(update(draggableModules, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragCard],
                ],
            }));
        },
        [draggableModules],
    );

    const generateModalByType = () => {
        if (Modal.showModal) {

            switch (Modal.type) {
                case MODAL_TYPES.ADD_MODULE.TYPE :
                    return <ModulesModal showModal={Modal} message={Modal.message} toggleModal={toggleModal}/>;

                case MODAL_TYPES.REMOVE_MODULE.TYPE :
                    return <ModalAction showModal={Modal} message={Modal.message} toggleModal={toggleModal}/>;

                case MODAL_TYPES.MANAGE_IMAGES.TYPE :
                    return <FilesModal showModal={Modal} message={Modal.message} toggleModal={toggleModal}/>;

                case MODAL_TYPES.SET_LINK.TYPE :
                    return <LinkModal showModal={Modal} message={Modal.message} toggleModal={toggleModal}/>;

                default:
                    return null;
            }
        }
        else {
            return null;
        }
    };

    const generateModuleByType = (module, index) => {
        switch (module.type) {
            case MODULES.HEADER.API_NAME :
                return <HeadingModule key={module._id}
                                      module={module}
                                      index={index}
                                      removeModuleTrigger={removeModuleTrigger}
                                      manageFileTrigger={manageFileTrigger}
                                      id={module._id}
                                      moveCard={moveCard}
                                      saveNavigationItemOrder={saveNavigationItemOrder}
                                      canModuleDrag={canModuleDrag}/>;

            case MODULES.TEXT.API_NAME :
                return <TextModule key={module._id}
                                   module={module}
                                   index={index}
                                   removeModuleTrigger={removeModuleTrigger}
                                   id={module._id}
                                   moveCard={moveCard}
                                   saveNavigationItemOrder={saveNavigationItemOrder}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.YESNO.API_NAME :
                return <YesNoModule key={module._id}
                                    module={module}
                                    index={index}
                                    removeModuleTrigger={removeModuleTrigger}
                                    id={module._id}
                                    moveCard={moveCard}
                                    saveNavigationItemOrder={saveNavigationItemOrder}
                                    canModuleDrag={canModuleDrag}/>;

            case MODULES.INPUT_TEXT.API_NAME :
                return <InputTextModule key={module._id}
                                    module={module}
                                    index={index}
                                    removeModuleTrigger={removeModuleTrigger}
                                    id={module._id}
                                    moveCard={moveCard}
                                    saveNavigationItemOrder={saveNavigationItemOrder}
                                    canModuleDrag={canModuleDrag}/>;


            case MODULES.INPUT_NUMBER.API_NAME :
                return <InputNumberModule key={module._id}
                                    module={module}
                                    index={index}
                                    removeModuleTrigger={removeModuleTrigger}
                                    id={module._id}
                                    moveCard={moveCard}
                                    saveNavigationItemOrder={saveNavigationItemOrder}
                                    canModuleDrag={canModuleDrag}/>;

            case MODULES.HEADER_TEXT.API_NAME :
                return <HeaderTextModule key={module._id}
                                         module={module}
                                         index={index}
                                         removeModuleTrigger={removeModuleTrigger}
                                         id={module._id}
                                         moveCard={moveCard}
                                         saveNavigationItemOrder={saveNavigationItemOrder}
                                         canModuleDrag={canModuleDrag}/>;

            case MODULES.SHORT_MENU.API_NAME :
                return <ShortMenuModule key={module._id}
                                        module={module}
                                        index={index}
                                        removeModuleTrigger={removeModuleTrigger}
                                        id={module._id}
                                        moveCard={moveCard}
                                        saveNavigationItemOrder={saveNavigationItemOrder}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.FILTER.API_NAME :
                return <FilterModule key={module._id}
                                     module={module}
                                     index={index}
                                     removeModuleTrigger={removeModuleTrigger}
                                     disabled={true}
                                     id={module._id}
                                     moveCard={moveCard}
                                     saveNavigationItemOrder={saveNavigationItemOrder}
                                     canModuleDrag={canModuleDrag}/>;

            case MODULES.AUDIO.API_NAME :
                return <AudioModule key={module._id}
                                    module={module}
                                    index={index}
                                    removeModuleTrigger={removeModuleTrigger}
                                    manageFileTrigger={manageFileTrigger}
                                    id={module._id}
                                    moveCard={moveCard}
                                    saveNavigationItemOrder={saveNavigationItemOrder}
                                    canModuleDrag={canModuleDrag}/>;

            case MODULES.VIDEO.API_NAME :
                return <VideoModule key={module._id} module={module} index={index}
                                    removeModuleTrigger={removeModuleTrigger}
                                    manageFileTrigger={manageFileTrigger}
                                    id={module._id}
                                    moveCard={moveCard}
                                    saveNavigationItemOrder={saveNavigationItemOrder}
                                    canModuleDrag={canModuleDrag}/>;

            case MODULES.PHOTO.API_NAME :
                return <PhotoModule key={module._id} module={module} index={index}
                                    removeModuleTrigger={removeModuleTrigger}
                                    manageFileTrigger={manageFileTrigger}
                                    id={module._id}
                                    moveCard={moveCard}
                                    saveNavigationItemOrder={saveNavigationItemOrder}
                                    canModuleDrag={canModuleDrag}/>;

            case MODULES.LIST.API_NAME :
                return <ListModule key={module._id} module={module} index={index}
                                   removeModuleTrigger={removeModuleTrigger}
                                   id={module._id}
                                   moveCard={moveCard}
                                   saveNavigationItemOrder={saveNavigationItemOrder}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.GALLERY.API_NAME :
                return <GalleryModule key={module._id} module={module} index={index}
                                      removeModuleTrigger={removeModuleTrigger}
                                      manageFileTrigger={manageFileTrigger}
                                      id={module._id}
                                      moveCard={moveCard}
                                      saveNavigationItemOrder={saveNavigationItemOrder}
                                      canModuleDrag={canModuleDrag}/>;

            case MODULES.POI.API_NAME :
                return <PoiModule key={module._id} module={module} index={index}
                                  removeModuleTrigger={removeModuleTrigger}
                                  manageFileTrigger={manageFileTrigger}
                                  id={module._id}
                                  moveCard={moveCard}
                                  saveNavigationItemOrder={saveNavigationItemOrder}
                                  canModuleDrag={canModuleDrag}/>;

            case MODULES.PATH.API_NAME :
                return <PathModule key={module._id} module={module} index={index}
                                   removeModuleTrigger={removeModuleTrigger}
                                   id={module._id}
                                   moveCard={moveCard}
                                   saveNavigationItemOrder={saveNavigationItemOrder}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.SHORT_LINK.API_NAME :
                return <ShortLinkModule key={module._id} module={module} index={index}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        id={module._id}
                                        moveCard={moveCard}
                                        saveNavigationItemOrder={saveNavigationItemOrder}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.LINK.API_NAME :
                return <LinkModule key={module._id} module={module} index={index}
                                   removeModuleTrigger={removeModuleTrigger}
                                   manageFileTrigger={manageFileTrigger}
                                   setLinkTrigger={setLinkTrigger}
                                   id={module._id}
                                   moveCard={moveCard}
                                   saveNavigationItemOrder={saveNavigationItemOrder}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.AR.API_NAME :
                return <ArModule key={module._id} module={module} index={index}
                                  removeModuleTrigger={removeModuleTrigger}
                                  manageFileTrigger={manageFileTrigger}
                                  id={module._id}
                                  moveCard={moveCard}
                                  saveNavigationItemOrder={saveNavigationItemOrder}
                                  canModuleDrag={canModuleDrag}/>;

            case MODULES.SELECT_ONE.API_NAME :
                return <SelectOneModule key={module._id} module={module} index={index}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        id={module._id}
                                        moveCard={moveCard}
                                        saveNavigationItemOrder={saveNavigationItemOrder}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.SELECT_ONE_MULTIPLE.API_NAME :
                return <SelectOneMultipleModule key={module._id} module={module} index={index}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        id={module._id}
                                        moveCard={moveCard}
                                        saveNavigationItemOrder={saveNavigationItemOrder}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.SELECT_MULTIPLE.API_NAME :
                return <SelectMultipleModule key={module._id} module={module} index={index}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        id={module._id}
                                        moveCard={moveCard}
                                        saveNavigationItemOrder={saveNavigationItemOrder}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.SAVE.API_NAME :
                return <SaveModule key={module._id} module={module} index={index}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        id={module._id}
                                        moveCard={moveCard}
                                        saveNavigationItemOrder={saveNavigationItemOrder}
                                        canModuleDrag={canModuleDrag}/>;

            default:
                return <p>{module.type}</p>
        }
    };

    const generateTemplateModuleToAdd = (moduleType) => {
        switch (moduleType) {
            case MODULES.HEADER.API_NAME :
                return <HeadingModule module={MOCK_MODULES[MODULES.HEADER.API_NAME]}
                                      index={modules.length + 1}
                                      id={modules.length + 1}
                                      moveCard={moveCard}
                                      key={modules.length + 1}
                                      removeModuleTrigger={removeModuleTrigger}
                                      manageFileTrigger={manageFileTrigger}
                                      canModuleDrag={canModuleDrag}/>;

            case MODULES.TEXT.API_NAME :
                return <TextModule module={MOCK_MODULES[MODULES.TEXT.API_NAME]}
                                   index={modules.length + 1}
                                   removeModuleTrigger={removeModuleTrigger}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.HEADER_TEXT.API_NAME :
                return <HeaderTextModule module={MOCK_MODULES[MODULES.HEADER_TEXT.API_NAME]}
                                         index={modules.length + 1}
                                         removeModuleTrigger={removeModuleTrigger}
                                         canModuleDrag={canModuleDrag}/>;

            case MODULES.YESNO.API_NAME :
                return <YesNoModule module={MOCK_MODULES[MODULES.YES_NO.API_NAME]}
                                            index={modules.length + 1}
                                            removeModuleTrigger={removeModuleTrigger}
                                            canModuleDrag={canModuleDrag}/>;

            case MODULES.INPUT_TEXT.API_NAME :
                return <InputTextModule module={MOCK_MODULES[MODULES.YES_NO.API_NAME]}
                                            index={modules.length + 1}
                                            removeModuleTrigger={removeModuleTrigger}
                                            canModuleDrag={canModuleDrag}/>;

            case MODULES.INPUT_NUMBER.API_NAME :
                return <InputNumberModule module={MOCK_MODULES[MODULES.YES_NO.API_NAME]}
                                            index={modules.length + 1}
                                            removeModuleTrigger={removeModuleTrigger}
                                            canModuleDrag={canModuleDrag}/>;

            case MODULES.AUDIO.API_NAME :
                return <AudioModule module={MOCK_MODULES[MODULES.AUDIO.API_NAME]}
                                    index={modules.length + 1}
                                    removeModuleTrigger={removeModuleTrigger}
                                    manageFileTrigger={manageFileTrigger}
                                    canModuleDrag={canModuleDrag}/>;
            case MODULES.VIDEO.API_NAME :
                return <VideoModule module={MOCK_MODULES[MODULES.VIDEO.API_NAME]}
                                    index={modules.length + 1}
                                    removeModuleTrigger={removeModuleTrigger}
                                    manageFileTrigger={manageFileTrigger}
                                    canModuleDrag={canModuleDrag}/>;
            case MODULES.PHOTO.API_NAME :
                return <PhotoModule module={MOCK_MODULES[MODULES.PHOTO.API_NAME]}
                                    index={modules.length + 1}
                                    removeModuleTrigger={removeModuleTrigger}
                                    manageFileTrigger={manageFileTrigger}
                                    canModuleDrag={canModuleDrag}/>;
            case MODULES.FILTER.API_NAME :
                return <FilterModule disabled={false}
                                     module={MOCK_MODULES[MODULES.FILTER.API_NAME]}
                                     index={modules.length + 1}
                                     removeModuleTrigger={removeModuleTrigger}
                                     canModuleDrag={canModuleDrag}/>;
            case MODULES.SHORT_MENU.API_NAME :
                return <ShortMenuModule module={MOCK_MODULES[MODULES.SHORT_MENU.API_NAME]}
                                        index={modules.length + 1}
                                        removeModuleTrigger={removeModuleTrigger}
                                        canModuleDrag={canModuleDrag}/>;
            case MODULES.LIST.API_NAME :
                return <ListModule disabled={false}
                                   module={MOCK_MODULES[MODULES.LIST.API_NAME]}
                                   index={modules.length + 1}
                                   removeModuleTrigger={removeModuleTrigger}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.PATH.API_NAME :
                return <PathModule disabled={false}
                                   module={MOCK_MODULES[MODULES.PATH.API_NAME]}
                                   index={modules.length + 1}
                                   removeModuleTrigger={removeModuleTrigger}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.GALLERY.API_NAME :
                return <GalleryModule module={MOCK_MODULES[MODULES.GALLERY.API_NAME]}
                                      index={modules.length + 1}
                                      removeModuleTrigger={removeModuleTrigger}
                                      manageFileTrigger={manageFileTrigger}
                                      canModuleDrag={canModuleDrag}/>;

            case MODULES.POI.API_NAME :
                return <PoiModule module={MOCK_MODULES[MODULES.POI.API_NAME]}
                                  index={modules.length + 1}
                                  removeModuleTrigger={removeModuleTrigger}
                                  manageFileTrigger={manageFileTrigger}
                                  canModuleDrag={canModuleDrag}/>;

            case MODULES.SHORT_LINK.API_NAME :
                return <ShortLinkModule module={MOCK_MODULES[MODULES.SHORT_LINK.API_NAME]}
                                        index={modules.length + 1}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.LINK.API_NAME :
                return <LinkModule module={MOCK_MODULES[MODULES.LINK.API_NAME]}
                                   index={modules.length + 1}
                                   removeModuleTrigger={removeModuleTrigger}
                                   manageFileTrigger={manageFileTrigger}
                                   setLinkTrigger={setLinkTrigger}
                                   canModuleDrag={canModuleDrag}/>;

            case MODULES.AR.API_NAME :
                return <ArModule module={MOCK_MODULES[MODULES.AR.API_NAME]}
                                  index={modules.length + 1}
                                  removeModuleTrigger={removeModuleTrigger}
                                  manageFileTrigger={manageFileTrigger}
                                  canModuleDrag={canModuleDrag}/>;

            case MODULES.SELECT_ONE.API_NAME :
                return <SelectOneModule module={MOCK_MODULES[MODULES.SELECT_ONE.API_NAME]}
                                        index={modules.length + 1}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.SELECT_ONE_MULTIPLE.API_NAME :
                return <SelectOneMultipleModule module={MOCK_MODULES[MODULES.SELECT_ONE_MULTIPLE.API_NAME]}
                                        index={modules.length + 1}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.SELECT_MULTIPLE.API_NAME :
                return <SelectMultipleModule module={MOCK_MODULES[MODULES.SELECT_MULTIPLE.API_NAME]}
                                        index={modules.length + 1}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        canModuleDrag={canModuleDrag}/>;

            case MODULES.SAVE.API_NAME :
                return <SaveModule module={MOCK_MODULES[MODULES.SAVE.API_NAME]}
                                        index={modules.length + 1}
                                        removeModuleTrigger={removeModuleTrigger}
                                        manageFileTrigger={manageFileTrigger}
                                        setLinkTrigger={setLinkTrigger}
                                        canModuleDrag={canModuleDrag}/>;

            default:
                return null
        }
    };


    // --- NAV-ITEM TYPE AR MUST CONTAIN ONLY ONE AR MODULE ---
    const onlyOneArValidation = () => {
        if(currentNavigationItem){
            if(currentNavigationItem.type !== 'ar') return true;
            else return !currentNavigationItem.modules.some(module => module.type === 'ar');
        }
    };

    return (
        <>
            <Prompt when={moduleEditableData !== undefined}
                    message={() => {
                        if (moduleEditableData) dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
                    }}
            />
            <NavigationItemData/>
            <Separator>
                MODUŁY
            </Separator>
            <ContentWrapperWithSpinner isLoading={isLoading}>
                <DndProvider backend={Backend}>
                    <Wrapper>
                        {draggableModules && draggableModules.map((module, index) => <React.Fragment
                            key={module._id}>{generateModuleByType(module, index)}</React.Fragment>)}
                        {generateTemplateModuleToAdd(moduleToAddType)}
                    </Wrapper>
                </DndProvider>
                {!moduleToAddType && !moduleEditableData && onlyOneArValidation() &&
                <ActionBar actionType={'add'} noModules={modules && !modules.length}
                           action={addModuleTrigger}/>
                }
            </ContentWrapperWithSpinner>
            {generateModalByType()}
            {isError && <ModalError isError={isError}/>}
        </>
    )
};

export default NavigationItem;