import styled, {css} from "styled-components";
import React from "react";
import {Link, useParams, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {routes} from '../../../routes';

const MenuItemWrapper = styled.li`
   width: 100%;
   min-height: 3rem;
   display: flex;
   margin-bottom: 1rem;
   text-decoration: none;
   list-style: none;
   position: relative;
   align-items: center;
   color: black;
   
   ${({active}) =>
    active &&
    css`
          color: ${({theme}) => theme.app_orange};
   `}

   &::first-letter {
    text-transform: uppercase;
    }
`;

const TextWrapper = styled.span`
  display: flex;
  cursor: pointer;
  letter-spacing: 0.2px;
  font-size: ${({theme}) => theme.fontSize.l}
  &::first-letter {
    text-transform: uppercase;
    }
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
`;


const MenuItemActive = styled.div`
   width: 2.3rem;
   height: 0.3rem;
   display: flex;
   background-color: ${({theme}) => theme.app_orange};
   margin-bottom: 1rem;
   position: absolute;
   left: -3.5rem;
`;

const MenuItem = React.memo(({item}) => {

    let active = false;
    const canUserChangePath = useSelector(state => !state.isModuleEditing);
    const history = useHistory();

    let {navItemId} = useParams();
    if (navItemId === item._id) active = true;

    const redirect = () => {
        history.push(routes.navigation_item.replace(':navItemId',item._id));
    }

    return (
        active ?
            <MenuItemWrapper active>
                <MenuItemActive/>
                <TextWrapper>
                    {item.name}
                </TextWrapper>
            </MenuItemWrapper>
            :
            <>
                {canUserChangePath ?
                    <MenuItemWrapper onClick={redirect}>
                        <TextWrapper>
                            {item.name}
                        </TextWrapper>
                    </MenuItemWrapper>
                    :
                    <MenuItemWrapper onClick={() => alert('Nie można zmienić lokalizacji - modul w trakcie edycji.')}>
                        <TextWrapper>
                            {item.name}
                        </TextWrapper>
                    </MenuItemWrapper>
                }
            </>

    )
});
export default MenuItem;