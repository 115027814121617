import styled, {css} from "styled-components";
import React from "react";
import MenuItem from "../atoms/buttons/MenuItem";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {routes} from '../../routes';

const MenuWrapper = styled.ul`
   width: 95%;
   height: 50%;
   display: flex;
   margin-top: 5rem;
   flex-direction: column;
   list-style: none;
   text-decoration: none;
   padding: 0;
   color: black;
`;

const Button = styled.button`
    width: 100%;
   min-height: 3rem;
   display: flex;
   margin-bottom: 1rem;
   text-decoration: none;
   list-style: none;
   position: relative;
   align-items: center;
   color: black;
   background: transparent;
   border: none;
   padding: 0px;
   
   ${({active}) =>
    active &&
    css`
          color: ${({theme}) => theme.app_orange};
   `}

   &::first-letter {
    text-transform: uppercase;
   }

   display: flex;
  cursor: pointer;
  letter-spacing: 0.2px;
  font-size: ${({theme}) => theme.fontSize.l}
  &::first-letter {
    text-transform: uppercase;
    }
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
`;

const Menu = () => {
    const navigationItemsList = useSelector(state => state.navigationItemsList);
    const history = useHistory();
    const redirect = () => {
        history.push(routes.users);
    };

    return (
        <MenuWrapper>
            {navigationItemsList && navigationItemsList.map(item => <MenuItem key={item._id} item={item}/>)}
            <Button onClick={redirect}> Uzytkownicy </Button>
        </MenuWrapper>
    )
};
export default Menu;

