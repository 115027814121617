import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {FETCH_USERS} from "../../actions/requestTypes";
import {requestUsers} from '../../actions/index';

// Components
import User from "../organisms/User";
import UserListHeader from "../atoms/UserListHeader"
import List from "../atoms/List";
// import Pagination from "../Pagination";
import Separator from "../molecules/Separator";
import {DnDWrapper} from "../atoms/wrappers/DnDWrapper";
import {ModuleHeading} from "../atoms/headings/ModuleHeading";

const UserList = ({}) => {

    const dispatch = useDispatch();
    const fetchUsers = (pageNo, limit, filters = {}) => {
        dispatch(requestUsers({
                ...FETCH_USERS,
            },
            {
                pageNo: pageNo,
                limit: limit,
                where: {
                    ...filters
                }
            })
        );
    };

    const isFetching = useSelector(state => state.isFetching);
    const users = useSelector(state => (state.users) ? state.users : []);

    console.log(users);

    const totalItems = useSelector(state => (state.users && state.users.totalItems) ? state.users.totalItems : 0);
    const totalPages = useSelector(state => (state.users && state.users.totalPages) ? state.users.totalPages : 0);

    const [pageNo, setPageNo] = useState(1); // 1 page number
    const [limit, setLimit] = useState(10); // 10 number of records

    // is being executed when limit or page number changed
    useEffect(() => {
        fetchUsers(pageNo, limit);
    }, [limit, pageNo]);

    const handleChange = () => {
        // if it is last item on the page change page number
        if(users.length == 1) {
            const pageNumber = pageNo - 1;
            setPageNo(pageNumber);
        }
        else {
            fetchUsers(pageNo, limit);
        }
    };

    return (
        <>
            <Separator>
                UZYTKOWNICY
            </Separator>
            <DnDWrapper>
                <ModuleHeading >
                    <UserListHeader/>
                </ModuleHeading>
            </DnDWrapper>

            <List>
                {isFetching ?
                        <></>
                        :
                        users.length ?
                            <>
                                {users.map(user =>
                                    <User user={user} key={user._id} onChange={handleChange} />
                                )}
                                {/* <Pagination
                                    totalCount={totalPages}
                                    limit={limit}
                                    setLimit={setLimit}
                                    pageNo={pageNo}
                                    setPageNo={setPageNo}
                                    migrationsDashboardPagination={true}
                                /> */}
                            </>
                            :
                            <div>No results found</div>
                }
            </List>
    </>
    );
};

export default UserList;