import React from "react";
import styled from "styled-components";
// Components
import RowBooStrap from 'react-bootstrap/Row';
import {Container} from 'react-bootstrap';

const RowContainer = styled.div`
    width: 100%;
    max-width: 100%;
    background-color: white;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: black;
`;

const RowContent = styled(RowBooStrap)`
    padding: 1rem;
    width: 100%;
    align-items: center;
    margin: 0 !important;
    
    // &:hover{
    //     color: white;
    //     width: 100%;
    //     background-color: #34303e;
    //     align-items: center;
    // }

    & a {
        color: black;
    }

    &:hover a{
        color: white;
    }
`;

const Row = ({children}) => (
    <RowContainer>
        <RowContent>{children}</RowContent>
    </RowContainer>
);

export default Row;