import styled from "styled-components";

export const NavigationItenDataWrapper = styled.div`
  flex: 0 0 auto;
  width: 100%;
  min-height: ${({minHeight}) => minHeight || '6rem'};
  height: auto;
  background-color: white;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: 1rem;
  display: flex;
  padding: 2rem 0 2rem 2rem;
  margin-bottom: 4rem;
`;