import styled  from 'styled-components';

const Input = styled.input`
  display: flex;
  padding: 10px 25px;
  font-size: ${({ theme }) => theme.fontSize.s};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: white;
  border: none;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  min-width: 20rem;
  height: 4rem;
  ::placeholder {
    color: ${({ theme }) => theme.app_grey};
  }
    &:focus{
      outline: none;
    }
`;
export default Input;

// width: ${({ width }) => width || '16vw'};