import styled, {css} from "styled-components";

const ModalItem = styled.button`
  width: 100%;
  height: 5rem;
  min-height: 5rem;
  background-color: white;
  border: none;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.8rem;
  font-size: ${({theme}) => theme.fontSize.l};
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 2rem;
  margin-top: 1.2rem;
  text-transform: uppercase;
  transition: background-color 0.1s ease-in-out;
  -webkit-transition: background-color 0.1s ease-in-out;
  
  ${({addNew}) =>
    addNew &&
    css`
        background-color: ${({theme}) => theme.app_grey_light};
    `}
  
  ${({item, activeItem}) =>
    item === activeItem &&
    css`
        background-color: ${({theme}) => theme.app_orange};
    `}
   
  &:focus{
     outline: none;
  }
  
  &:hover{
     background-color: ${({theme}) => theme.app_orange};
  }
  
  
  ${({isDeleteButton}) =>
    isDeleteButton &&
    css`
        //background-color: ${({theme}) => theme.app_orange};
        &:hover{
        background-color: ${({theme}) => theme.app_grey_light};
         }
    `}
  
  
`;

export default ModalItem;