import React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import Button from "../../atoms/buttons/Button";
import ModalItem from "../../atoms/buttons/ModalItem";
import modal_icon from "../../../assets/icons/add-modules-icon.svg"
import PortalBackground from "../../atoms/wrappers/PortalBackground";
import ModalCloseButton from "../../atoms/buttons/ModalCloseButton";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {connect} from "react-redux";
import {addNavigationItem, getAllNavigationItemList} from "../../../actions";
import {getLastElement} from "../../../utils/moduleFunctions";

const modalRoot = document.getElementById('modal-portal');

const PopUpContainer = styled.div`
   width: 40rem;
   height: auto;
   min-height: 60rem;
   max-height: 80vh;
   background-color: white;
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   justify-self: center;
   align-self: center;
   z-index: 999999;
   position: absolute; 
   padding: 3rem;
   align-items: center;
   padding-bottom: 8rem;
  
`;

const ModalHeaderWrapper = styled.div`
   display:flex;
   width: auto;
   height: auto;
   justify-content: center;
   margin-bottom: 2rem;
`;

const ModalHeader = styled.div`
   display: flex;
   margin: 0 auto;
   font-weight: ${({theme}) => theme.fontWeight.medium};
   font-size: ${({theme}) => theme.fontSize.xl};
   align-self: center;
   justify-content: center;
`;

const ModalIcon = styled.div`
   display: flex;
   width: 4rem;
   height:  4rem;
   background-image: url(${modal_icon});
   background-size: 3.5rem;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   margin-right: 1.5rem;
`;

const ButtonWrapper = styled.div`
   display: flex;
   width: 80%;
   height: auto;
   bottom: 2rem;
   position: absolute;
   align-self: center;
   justify-content: center;
`;

const ModulesWrapper = styled.div`
   display: flex;
   width: 85%;
   height: auto;
   overflow-y: scroll;
   flex-direction: column;
   padding: 0 10px 13px 10px;
`;


class LinkModal extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.activeItem = 'none'
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
        this.props.getAllNavigationItemListAction();
        this.setState({
            ...this.state,
            activeItem: this.getField(this.props.showModal.target.field.name, this.props.moduleEditableData).fieldValues[this.props.showModal.target.index].value,
        })
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    // --- GET FIELD FROM MODULE ---
    getField = (type, module) => ({
        ...module.fields.find(field => field.name === type),
    });


    // --- RETURNS FILTERED NAV-ITEM LIST ---
    getFilteredNavItemList = (allNavigationItems, linkType) => {
        if (allNavigationItems) {
            let filteredNavItemList;

            if (linkType) filteredNavItemList = allNavigationItems.filter(item => item.type === linkType);
            else filteredNavItemList = allNavigationItems;

            return filteredNavItemList.map(item => (
                <ModalItem onClick={() => this.setState({...this.state, activeItem: item._id})}
                           item={item._id}
                           key={item._id}
                           activeItem={this.state ? this.state.activeItem : null}>{item.name}</ModalItem>))
        }
    };

    render() {
        const {toggleModal, message, showModal, allNavigationItems, moduleEditableData, saveChanges, currentNavigationItem} = this.props;


        const checkIfModuleIsSelected = () => {
            if (this.state) {
                if (this.state.activeItem !== 'none') return false
            }
            else return true;
        };

        return ReactDOM.createPortal(
            <PortalBackground>
                <PopUpContainer>
                    <ModalCloseButton onClick={() => {
                        toggleModal({showModal: false, action: undefined})
                    }}/>
                    <ModalHeaderWrapper>
                        <ModalIcon/>
                        <ModalHeader>{message}</ModalHeader>
                    </ModalHeaderWrapper>
                    <ModulesWrapper>
                        {this.getFilteredNavItemList(allNavigationItems, showModal.linkType)}
                        {showModal.isModuleSaved &&
                        <ModalItem addNew
                                   onClick={() => {
                                       this.props.addNavigationItemAction({
                                           parentId: currentNavigationItem._id,
                                           type: showModal.linkType
                                       });
                                       toggleModal({showModal: false, action: undefined});
                                   }}
                                   item={'addNewNavigationItem'}
                                   key={'addNewNavigationItem'}
                                   activeItem={this.state ? this.state.activeItem : null}>DODAJ LINK</ModalItem>
                        }
                    </ModulesWrapper>
                    <ButtonWrapper>
                        <Button actionType={'add'} isDisabled={checkIfModuleIsSelected()} onClick={() => {

                            if (moduleEditableData) {
                                let newModule = moduleEditableData;
                                this.getField(showModal.target.field.name, newModule).fieldValues[showModal.target.index].value = this.state.activeItem;
                                saveChanges(newModule);
                            }
                            toggleModal({showModal: false, action: undefined});
                        }}>Zapisz link</Button>
                    </ButtonWrapper>
                </PopUpContainer>
            </PortalBackground>,
            this.el,
        );
    }
}

const mapStateToProps = state => ({
    allNavigationItems: state.allNavigationItems,
    moduleEditableData: state.moduleEditableData,
    currentNavigationItem: getLastElement(state.navigationItemController)
});

const mapDispatchToProps = dispatch => ({
    getAllNavigationItemListAction: () => dispatch(getAllNavigationItemList()),
    addNavigationItemAction: (data) => dispatch(addNavigationItem(data)),
    saveChanges: (module) => dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: module})
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LinkModal);