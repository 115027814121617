import styled from 'styled-components'

const LoginParagraph = styled.p`
  font-size: ${({theme, fontSize}) =>  fontSize || theme.fontSize.l};
  font-weight:  ${({theme}) => theme.fontWeight.medium};
  margin-top: ${({margintop}) =>  margintop || '1rem'};
  display: flex;
`;

export default LoginParagraph;
