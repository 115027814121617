import React from "react";
import {ModuleSpinnerWrapper} from "../atoms/wrappers/ModuleSpinnerWrapper";
import {SpinnerContainer} from "../HOC/WithSpinner";

const ModuleSpinner = () => (
    <ModuleSpinnerWrapper>
        <SpinnerContainer/>
    </ModuleSpinnerWrapper>
);

export default ModuleSpinner;