import React, {useEffect, useState} from "react";
import {MODULE_TYPES, MODULES} from "../../../utils/modules";
import {NavigationItenDataWrapper} from "../../atoms/wrappers/NavigationItemDataWrapper";
import Dropdown from "../../atoms/dropdowns/Dropdown";
import {useDispatch, useSelector} from "react-redux";
import ModuleInputWrapper from "../../molecules/ModuleInputWrapper";
import {ActionButton} from "../../molecules/ActionContainer";
import _ from "lodash";
import styled from "styled-components";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {updateNavigationItem} from "../../../actions";
import {getLastElement} from "../../../utils/moduleFunctions";
import {useParams} from "react-router-dom";

const VerticalSeparator = styled.div`
  display: flex;
  width: 1px;
  background-color: #E0E3E5;
`;

const ActionWrapper = styled.div`
  display: flex;
  min-width: 14%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
`;

const ErrorMessage = styled.div`
  display: flex;
  color: ${({theme}) => theme.app_orange};
  width: 100%;
  font-size: 1rem;
  font-weight: ${({theme}) => theme.fontWeight.regular};
  justify-content: flex-end;
  margin-top: -1rem;
`;

const NavigationItemData = ({removeNavigationItemTrigger, disabled = true}) => {

    const [isDisabled, setIsDisabled] = useState(disabled);
    const dispatch = useDispatch();
    let {navItemId} = useParams();
    const navigationItemController = useSelector(state => state.navigationItemController);
    const isModuleEditing = useSelector(state => state.isModuleEditing);
    const [currentNavigationItem, setCurrentNavigationItem] = useState();
    const [isNameEmpty, setIsNameEmpty] = useState(false);
    const [isTypeValid, setIsTypeValid] = useState(true);

    useEffect(() => {
        setCurrentNavigationItem(_.cloneDeep(getLastElement(navigationItemController)));
        setIsNameEmpty(false);
    }, [getLastElement(navigationItemController)]);

    useEffect(() => {
        if(!isDisabled){
            handleCancel();
        }
    }, [navItemId]);


    const handleNameChange = (value) => {
        const newCurrentNavigationItem = {...currentNavigationItem};
        newCurrentNavigationItem.name = value;
        setCurrentNavigationItem(newCurrentNavigationItem)
    };

    const checkIfTypeIsValid = (type) => {
        switch (type) {
            case 'content':
                if (currentNavigationItem.modules.some(m => m.type === 'poi' || m.type === 'path' || m.type === 'ar')) return false;
                break;
            case 'map':
                if (currentNavigationItem.modules.some(m => m.type !== 'poi' && m.type !== 'path')) return false;
                break;
            case 'ar':
                if (currentNavigationItem.modules.some(m => m.type !== 'ar')) return false;
                break;
            default:
                break;
        }
        return true;
    };

    const handleTypeChange = (value) => {
        setIsTypeValid(checkIfTypeIsValid(value));

        const newCurrentNavigationItem = {...currentNavigationItem};
        newCurrentNavigationItem.type = value;
        setCurrentNavigationItem(newCurrentNavigationItem)
    };


    const checkIsNameEmpty = () => currentNavigationItem && currentNavigationItem.name.trim() === "";

    const handleSave = () => {

        if (!checkIsNameEmpty()) {
            if (isTypeValid) {
                dispatch(updateNavigationItem(currentNavigationItem));
                setIsDisabled(true);
            }
        } else if(!isNameEmpty) setIsNameEmpty(true);
    };

    const handleCancel = () => {
        setCurrentNavigationItem(getLastElement(navigationItemController));
        setIsDisabled(true);
        setIsTypeValid(true);
        setIsNameEmpty(false);
    };

    // --- DATA VALIDATION ---
    const validateInput = (value) => {
        if (value.trim() === "") setIsNameEmpty(true);
        else if(isNameEmpty) setIsNameEmpty(false)
    };

    return (
        <NavigationItenDataWrapper>
            <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                <HorizontalModuleWrapper>
                    <ModuleInputWrapper marginRight={'3rem'}
                                        isDisabled={false}
                                        fieldValue={currentNavigationItem && currentNavigationItem.name}
                                        handleChange={(newValue) => {
                                            handleNameChange(newValue);
                                            validateInput(newValue);
                                        }}
                                        saveModuleTrigger={handleSave}
                                        inputType={'Nazwa'}
                                        error={checkIsNameEmpty()}
                    />
                    <Dropdown
                        value={currentNavigationItem && currentNavigationItem.type}
                        handleChange={(value) => handleTypeChange(value)}
                        inputType={MODULES.LINK_TYPE.UI_NAME}
                        options={MODULE_TYPES}
                        isTypeValid={isTypeValid}
                    />
                </HorizontalModuleWrapper>
                {!isTypeValid && <ErrorMessage>Wybrany typ nie jest kompatybilny z zawartymi modułami!</ErrorMessage>}
                <VerticalSeparator/>
            </ModuleContentWrapper>
            <VerticalSeparator/>
            <ActionWrapper>
                {isDisabled ?
                    <>
                        {!isModuleEditing && <ActionButton type={'edit'} onClick={() => {
                            setIsDisabled(false)
                        }}/>}
                        {/*<ActionButton type={'remove'} onClick={() => removeNavigationItemTrigger()}/>*/}
                    </>
                    :
                    <>
                        {isTypeValid && <ActionButton type={'save'} onClick={() => handleSave()}/>}
                        <ActionButton type={'close'} onClick={() => handleCancel()}/>
                    </>
                }
            </ActionWrapper>

        </NavigationItenDataWrapper>
    )
};
export default NavigationItemData;