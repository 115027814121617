import React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";
import ModalItem from "../../atoms/buttons/ModalItem";
import PortalBackground from "../../atoms/wrappers/PortalBackground";

const modalRoot = document.getElementById('modal-portal');

const PopUpContainer = styled.div`
   width: 50rem;
   min-height: 25rem;
   background-color: white;
   border-radius: 1rem;
   display: flex;
   flex-direction: column;
   margin: 0 auto;
   justify-self: center;
   align-self: center;
   z-index: 999999;
   position: absolute; 
   padding: 3rem;
   align-items: center;
  
`;

const ModalHeaderWrapper = styled.div`
   display:flex;
   width: auto;
   height: auto;
   justify-content: center;
   margin-bottom: 2rem;
`;

const ModalHeader = styled.div`
   display: flex;
   margin: 0 auto;
   font-weight: ${({theme}) => theme.fontWeight.medium};
   font-size: ${({theme}) => theme.fontSize.xl};
   align-self: center;
   justify-content: center;
   text-align: center;
`;

const ModulesWrapper = styled.div`
   display: flex;
   width: 85%;
   height: auto;
   flex-direction: column;
`;

class ModalAction extends React.Component {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.activeItem = 'none'
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        modalRoot.removeChild(this.el);
    }

    render() {
        const {toggleModal, message, showModal} = this.props;

        return ReactDOM.createPortal(
            <PortalBackground>
                <PopUpContainer>
                    <ModalHeaderWrapper>
                        <ModalHeader>{message}</ModalHeader>
                    </ModalHeaderWrapper>
                    <ModulesWrapper>
                        <ModalItem item={'none'} onClick={() => {
                            showModal.action();
                            toggleModal({showModal: false, action: undefined});
                        }}>TAK</ModalItem>
                        <ModalItem item={'none'} isDeleteButton onClick={() => {
                            toggleModal({showModal: false, action: undefined})
                        }}>NIE</ModalItem>
                    </ModulesWrapper>
                </PopUpContainer>
            </PortalBackground>,
            this.el,
        );
    }
}

export default ModalAction;