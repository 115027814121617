import React, {useEffect, useState, useRef} from "react";
import {INPUT_TYPES, MODULES} from "../../../utils/modules";
import ActionContainer, {ActionButton} from "../../molecules/ActionContainer";
import {ModuleWrapper} from "../../atoms/wrappers/ModuleWrapper";
import {HorizontalModuleWrapper} from "../../atoms/wrappers/HorizontalModuleWrapper";
import {ModuleContentWrapper} from "../../atoms/wrappers/ModuleContentWrapper";
import {ModuleHeading} from "../../atoms/headings/ModuleHeading";
import {useDispatch, useSelector} from "react-redux";
import {addItem, API_URL, editItem} from "../../../actions";
import {useLocation} from "react-router-dom";
import styled, {css} from "styled-components";
import ModuleInputWrapper from "../../molecules/ModuleInputWrapper";
import Button from "../../atoms/buttons/Button";
import {ACTION_TYPES} from "../../../actions/actionTypes";
import {EDIT_MODULE} from "../../../actions/requestTypes";
import _ from "lodash";
import {
    checkIsModuleLoading,
    getFieldValue,
    getLastElement,
    getModuleId,
    isError,
    moduleEmptyFieldsValidation
} from "../../../utils/moduleFunctions";
import ModuleSpinner from "../../molecules/ModuleSpinner";
import {useDrag, useDrop} from "react-dnd";
import {DnDWrapper} from "../../atoms/wrappers/DnDWrapper";


const GalleryWrappper = styled.div`
   display: flex;
   width: 100%;
   max-width: 100%;
   height: 18rem;
   min-height: 15rem;
   padding: 10px;
   overflow: scroll;
   flex-flow: row wrap;
   background-color: ${({theme}) => theme.app_grey_light};
   
  ${({error}) =>
    error &&
    css`
        border: 1px solid ${({theme}) => theme.app_orange};
   `}
`;

const ActionWrapper = styled.div`
   display: flex;
   width: 2.5rem;
   height: 2.5rem;
   min-height: 2.5rem;
   position: absolute;
   right: 0;
   top: 0;
`;

const FileWrapper = styled.div`
   display: flex;
   width: 15rem;
   height: 100%;
   background-color: white;
   margin-right: 1rem;
   margin-bottom: 5px;
   color: ${({theme}) => theme.app_grey};
   font-size: ${({theme}) => theme.fontSize.s};
   flex-direction: column;
   word-wrap: break-word;
   padding: 6px;
   border-radius: 0.5rem;
   position: relative;
   
   span{
      color: #4d4d4d;
      font-weight: ${({theme}) => theme.fontWeight.medium};
      margin-top: 3px;
   }
   
`;

const FileImage = styled.div`
   display: flex;
   width: 100%;
   height: 80%;
   max-height: 11vw;
   background-color: gray;
   margin-bottom: 2px;
   background-size: cover;
   background-repeat: no-repeat;
   background-position-x: center;
   background-position-y: center;
   
    ${({imageUrl}) =>
    imageUrl &&
    css`
        background-image: url('${imageUrl}');
    `}
   
`;

const ErrorDesriptionGallery = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: auto;
  font-size: 1rem;
  color: ${({theme}) => theme.app_orange};
  position:absolute;
  bottom: -1.7rem;
`;

const GalleryErrorWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;  
  position:relative;
`;


const GalleryModule = ({module, index, id, removeModuleTrigger, disabled = true, manageFileTrigger, moveCard,canModuleDrag,saveNavigationItemOrder}) => {


    //  ############### DnD ###############
    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: 'MODULE',
        hover(item, monitor) {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) return;
            const hoverBoundingRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex
        },
    });
    const [{isDragging}, drag] = useDrag({
        item: {type: 'MODULE', id, index},
        canDrag: canModuleDrag,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: () => saveNavigationItemOrder(),
    });
    drag(drop(ref));

//  ############### GENERAL MODULE HANDLER ###############

    // --- INITIALIZATION ---
    let location = useLocation();
    const dispatch = useDispatch();
    const [isDisabled, setIsDisabled] = useState(disabled);
    const [moduleEmptyFields, setModuleEmptyFields] = useState([]);

    // --- GET MODULE VALUES FROM REDUX ---
    const moduleEditableData = useSelector(state => state.moduleEditableData);
    const currentNavItem = useSelector(state => {
            if (state.navigationItemController && state.navigationItemController.length)
                return getLastElement(state.navigationItemController);
        }
    );


    (() => {
        if (!isDisabled && moduleEditableData) module = moduleEditableData;
    })();

    // --- GET TYPE FROM ENUM ---
    const getModuleType = () => MODULES[module.type.toUpperCase()];

    //--- CHECK IF MODULE IS CREATED BY USER ---
    const moduleToAdd = useSelector(state => state.moduleToAdd);
    const isCreatedByUser = (moduleToAdd === getModuleType().API_NAME);

    // --- GET FIELD FROM MODULE ---
    const getField = (type, moduleToSearch) => ({
        ...moduleToSearch.fields.find(field => field.name === type),
        module_index: index,
    });

    //--- ADD MODULE TO REDUX IF IS CREATED BY USER ---
    useEffect(() => {
        if (isCreatedByUser) {
            const moduleToEdit = _.cloneDeep(module);
            dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: moduleToEdit});
            setIsDisabled(false);
            setModuleEmptyFields([]);
        }
    }, []);

    // --- FIELD VALUE CHANGE ---
    const handleValueChange = (value, fieldType) => {
        let newModule = {...moduleEditableData};
        getField(fieldType, newModule).fieldValues[0].value = value;
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
    };

    // --- EDIT REJECTED ---
    const resetValuesToDefault = () => {
        if (isCreatedByUser) {
            dispatch({type: ACTION_TYPES.MODULE_CANCEL_ADDING});
        }
        else {
            if (currentNavItem.modules && currentNavItem.modules.length) {
                module = currentNavItem.modules[index];
            }
            dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
        }
        setModuleEmptyFields([]);
        setIsDisabled(true)
    };

    // --- SAVE MODULE ---
    const saveModuleTrigger = () => {
        let emptyFields = moduleEmptyFieldsValidation(moduleEditableData);

        // --- GALLERY FIELD VALIDATION ---
        if (getFieldValueGallery(galleryField).trim() === "") emptyFields.push(galleryField);

        if (!emptyFields.length) {
            if (isCreatedByUser) {
                dispatch(addItem(getModuleId(location), moduleEditableData, currentNavItem.modules));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.SAVE_CREATED_MODULE});
            } else {
                dispatch(editItem(getModuleId(location), EDIT_MODULE, moduleEditableData));
                resetValuesToDefault();
                dispatch({type: ACTION_TYPES.TOGGLE_MODULE_EDIT, payload: false});
                setIsDisabled(true)
            }
        } else setModuleEmptyFields(emptyFields)
    };

    // --- HANDLE LOADING SPINNER ---
    const isModuleLoading = useSelector(state => state.isModuleLoading);

//  ############################################################


    // --- REMOVE PHOTO ---
    const removeFieldTrigger = (index) => {
        const newModule = {...moduleEditableData};
        getField(MODULES.GALLERY.API_FIELD_NAME, newModule).fieldValues.splice(index, 1);
        dispatch({type: ACTION_TYPES.EDIT_MODULE_DATA, payload: newModule});
        validateInput(getFieldValueGallery(galleryField), galleryField);
    };

    // --- FIELDS ---
    const galleryDescriptionField = getField(MODULES.DESCRIPTION.API_NAME, module);
    const galleryField = getField(MODULES.GALLERY.API_FIELD_NAME, module);


    const getFieldValueGallery = (field) => {
        if (field && field.fieldValues.length) return field.fieldValues[0].value;
        else return '';
    };


    // --- DATA VALIDATION ---
    const validateInput = (value, fieldToValidate) => {
        if (value.trim() === "") setModuleEmptyFields([...moduleEmptyFields, fieldToValidate]);
        else if (moduleEmptyFields.length) setModuleEmptyFields(moduleEmptyFields.filter(field => field.name !== fieldToValidate.name))
    };

    // --- POPUP FIELD VALIDATION ---
    useEffect(() => {
        if (moduleEmptyFields.length) {
            if (getFieldValueGallery(galleryField).trim() === "") {
                setModuleEmptyFields([...moduleEmptyFields, galleryField]);
            }
            else setModuleEmptyFields(moduleEmptyFields.filter(field => field.name !== galleryField.name))
        }
    }, [getFieldValueGallery(galleryField)]);

    const opacity = isDragging ? 0 : 1;

    const getRef = () => isCreatedByUser? null : ref;

    return (
        <DnDWrapper ref={getRef()} style={{opacity}}>
            <ModuleHeading canModuleDrag={canModuleDrag} isDisabled={isDisabled} isLoading={checkIsModuleLoading(module._id, isModuleLoading)}>
                {getModuleType().UI_NAME}
            </ModuleHeading>
            <ModuleWrapper>
                {checkIsModuleLoading(module._id, isModuleLoading) && <ModuleSpinner/>}
                <ModuleContentWrapper style={isDisabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
                    <HorizontalModuleWrapper>
                        <GalleryErrorWrapper>
                            <GalleryWrappper error={isError(galleryField, moduleEmptyFields)}>
                                {galleryField.fieldValues.map((photo, index) =>
                                    <FileWrapper key={photo._id}>
                                        <FileImage imageUrl={`${API_URL}${photo.value}`}/>
                                        <span>{photo.value}</span>
                                        <ActionWrapper>
                                            <ActionButton minWidth={'1rem'} removeGalleryItem type={'remove'}
                                                          onClick={() => removeFieldTrigger(index)}/>
                                        </ActionWrapper>
                                    </FileWrapper>
                                )}
                            </GalleryWrappper>
                            {isError(galleryField, moduleEmptyFields) &&
                            <ErrorDesriptionGallery>Pole nie może być puste.</ErrorDesriptionGallery>
                            }
                        </GalleryErrorWrapper>
                    </HorizontalModuleWrapper>
                    <HorizontalModuleWrapper>
                        <ModuleInputWrapper marginRight={'3rem'}
                                            isDisabled={isDisabled}
                                            fieldValue={getFieldValue(galleryDescriptionField)}
                                            handleChange={(value) => {
                                                handleValueChange(value, MODULES.DESCRIPTION.API_NAME);
                                                validateInput(value, galleryDescriptionField)
                                            }}
                                            inputType={INPUT_TYPES.TEXT}
                                            saveModuleTrigger={saveModuleTrigger}
                                            error={isError(galleryDescriptionField, moduleEmptyFields)}
                        />
                        <Button Smaller={true} onClick={(e) => {
                            e.preventDefault();
                            manageFileTrigger(galleryField);
                        }}>Dodaj zdjęcie</Button>
                    </HorizontalModuleWrapper>
                </ModuleContentWrapper>
                <ActionContainer isDisabled={isDisabled} setIsDisabled={setIsDisabled}
                                 removeModuleTrigger={removeModuleTrigger} idToRemove={module._id}
                                 saveModuleTrigger={saveModuleTrigger}
                                 resetValuesToDefault={resetValuesToDefault}
                                 module={module}
                />
            </ModuleWrapper>
        </DnDWrapper>
    )
};
export default GalleryModule;