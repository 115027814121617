import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {routes} from "../../../routes/index";
import InfoParagraph from "../../atoms/paragraphs/InfoParagraph";
import {Redirect, useLocation} from "react-router-dom";

const RouteErrorWrapper = styled.div`
   width: 100vw;
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-family: 'Roboto', sans-serif;
   font-size: ${({theme}) => theme.fontSize.xl};
   overflow: hidden;
   
   h1{
      font-size: 6rem;
      margin: 0 0 1rem;
   }
`;

const Logo = styled.div`
   width: 15rem;
   background-size: cover;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   display: flex;
   position: absolute;
   top: 3rem;
   left: 3rem;
   max-width: 237px;
   
   &:after{
   content: '';
   display: block;
   padding-bottom: 100%;
   }
`;


const Background = styled.img`
   width: 100%;
   height: 100%;
   position:absolute;
   opacity: 0;
   transition: opacity 1s ease-in-out;
   -webkit-transition: opacity 1s ease-in-out;
   z-index: -1;
`;

const NoMatch = () => {

    let location = useLocation();
    if (location.pathname === routes.home) {
        return <Redirect to={routes.login}/>;
    }

    return (
        <RouteErrorWrapper>
            <Logo/>
            <h1>404</h1>
            <InfoParagraph margintop={'0'}>Nie znaleziono strony.</InfoParagraph>
            <InfoParagraph margintop={'4rem'} as={Link} to={routes.login}>{'<'} Wróć na stronę główną</InfoParagraph>
            <Background onLoad={(e) => e.target.style.opacity = '0.1'}/>
        </RouteErrorWrapper>
    )
};
export default NoMatch;