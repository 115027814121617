import React from "react";
import styled from "styled-components";

// Components
import {ListHeader, ListHeaderCol} from './ListHeader';

const DownloadListHeaderCol = styled(ListHeaderCol)`
    text-align: right;
`;

const HubListHeader = () => {

    return (
        <ListHeader>
            <ListHeaderCol sm={4}> IMIE </ListHeaderCol>
            <ListHeaderCol sm={4}> ADRES EMAIL </ListHeaderCol>
            <DownloadListHeaderCol sm={4}> RAPORT </DownloadListHeaderCol>
        </ListHeader>
    )
};

export default HubListHeader;