import React from "react";
import styled from "styled-components";

const ListWrapper = styled.div`
   width: 100%;
   min-height: calc(100vh - 306px);
   background-color: transparent;
   display: flex;
   justify-content: center;
`;

const ListContainer = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
margin: 0;
`;

const List = ({children}) => (
    <ListWrapper>
        <ListContainer>
            {children}
        </ListContainer>
    </ListWrapper>
);

export default List;