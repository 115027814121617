import styled, {css} from "styled-components";

export const ModuleHeading = styled.div`
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  background-color: #cfdf8b;
  box-shadow: 0 7px 15px 0 rgba(0, 0, 0, 0.04), 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  color: black;
  padding-left: 2rem;
  margin-top: 2rem;
  transition: all 0.4s ease-in-out;
  -webkit-transition: all  0.4s ease-in-out;
  position:relative;
  
  
  ${({isDisabled}) =>
    isDisabled === false &&
    css`
    background-color: ${({theme}) => theme.app_green};
    color:black;
   `}
   
  ${({isLoading}) =>
    isLoading === true &&
    css`
    background-color: ${({theme}) => theme.app_grey_light};
    color:${({theme}) => theme.app_grey};
   `}

  ${({canModuleDrag}) =>
    canModuleDrag === true &&
    css`
        cursor: move;
   `}
`;
