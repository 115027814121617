import styled from "styled-components";
import close_icon from "../../../assets/icons/close-dark.svg"

const ModalCloseButton = styled.div`
   position:absolute;
   right: 1.5rem;
   top: 1.5rem;
   display: flex;
   width: 2.5rem;
   height: 2.5rem;
   border-radius: 0.5rem;
   background-color: ${({theme}) => theme.app_grey};
   background-image: url(${close_icon});
   background-size: 1.5rem;
   background-position: 50% 50%;
   background-repeat: no-repeat;
   cursor: pointer;
   opacity: .3;
   transition: opacity 0.2s ease-in-out;
   -webkit-transition: opacity 0.2s ease-in-out;
   
   &:hover{
   opacity: 1;
   }
`;

export default ModalCloseButton;